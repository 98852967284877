import React, {useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import sendMail from '../../services/sendMail'

export default function MyVerticallyCenteredModal(props) {

  const [formData, setFormData] = useState({});
  const [formSent, setFormSent] = useState(false);

  const setValue = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendMail("info@corsnet.lk", formData.email, [
      {
        key: "Name",
        value: formData.name,
      },
      {
        key: "Email",
        value: formData.email,
      },
      {
        key: "Phone Number",
        value: formData.phone,
      },
      {
        key: "Message",
        value: formData.message,
      },
    ]);
    setFormSent(true)
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="text-center">Post Processing</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-container">
          <form
            name="post-processing-form"
            method="post"
          >
            <Form.Group
              className="form-field-control mb-2"
              controlId="formName"
            >
              <Form.Control 
                type="text" 
                placeholder="Name" 
                value={formData.name || ""}
                onChange={(e) => setValue("name", e.target.value)} 
                name="Name" />
            </Form.Group>

            <Form.Group
              className="form-field-control mb-2"
              controlId="formEmail"
            >
              <Form.Control 
                type="email" 
                placeholder="Email"
                value={formData.email || ""}
                onChange={(e) => setValue("email", e.target.value)} 
                name="Email" 
              />
            </Form.Group>

            <Form.Group
              className="form-field-control mb-2"
              controlId="formPhoneNumber"
            >
              <Form.Control 
                type="phone" 
                placeholder="Phone"
                value={formData.phone || ""}
                onChange={(e) => setValue("phone", e.target.value)}  
                name="Phone" 
              />
            </Form.Group>

            <Form.Group className="form-field-control mb-2">
              <Form.Control
                as="textarea"
                placeholder="Message"
                style={{ height: "160px" }}
                value={formData.message || ""}
                onChange={(e) => setValue("message", e.target.value)}
                name="Message"
              />
            </Form.Group>

            <Button 
              type="submit"
              onClick={handleSubmit} 
              className="btn">
              {formSent? "Submitted": "Submit"}
            </Button>
            <p className={`ty-text ${formSent? 'd-block': 'd-none'} pt-4`}>
              Thank you for your inquiry. We'll get back to you soon
            </p>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
