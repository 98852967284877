import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import sendMail from "../../services/sendMail";
import Datepicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./rinex.scss";
import { AiOutlinePlusCircle } from "react-icons/ai";
import formatStationData from "./formatStationData";
import { useStaticQuery, graphql } from "gatsby";
import { setHours, setMinutes } from "date-fns";

const defaultStationData = { name: "", date: "", start: "", end: "" };

export default function MyVerticallyCenteredModal(props) {
  const [stationData, setStationData] = useState([defaultStationData]);
  const [formSent, setFormSent] = useState(false);

  const [componentArray, setComponentArray] = useState([]);
  useEffect(() => {
    setComponentArray([...componentArray, `${componentArray.length}`]);
  }, []);

  const [formData, setFormData] = useState({});

  const setValue = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    await sendMail("info@corsnet.lk", formData.email, [
      {
        key: "Name",
        value: formData.name,
      },
      {
        key: "Email",
        value: formData.email,
      },
      {
        key: "Phone Number",
        value: formData.phone,
      },
      ...formatStationData(stationData),
      {
        key: "Message",
        value: formData.message,
      },
    ]);
    setFormSent(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="text-center">Rinex Data</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="form-container">
          <form name="rinex-form" method="post">
            <Row>
              <Col className="col-12 col-md-4 p-0 pe-md-1">
                <Form.Group
                  className="form-field-control mb-2"
                  controlId="formName"
                >
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={formData.name || ""}
                    onChange={(e) => setValue("name", e.target.value)}
                    name="Name"
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-4 p-0 pe-md-1">
                <Form.Group
                  className="form-field-control mb-2"
                  controlId="formEmail"
                >
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={formData.email || ""}
                    onChange={(e) => setValue("email", e.target.value)}
                    name="Email"
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 col-md-4 p-0">
                <Form.Group
                  className="form-field-control mb-2"
                  controlId="formPhoneNumber"
                >
                  <Form.Control
                    type="phone"
                    placeholder="Phone"
                    value={formData.phone || ""}
                    onChange={(e) => setValue("phone", e.target.value)}
                    name="Phone"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="booking-container justify-content-between align-items-end mb-2">
              <Col className="col-10 col-md-11 p-0">
                <ul className="p-0 m-0">
                  {stationData.map((data, idx) => {
                    return (
                      <li key={idx}>
                        <Booking
                          index={idx}
                          dataPassed={data}
                          setData={(id, value) => {
                            // console.log(idx, id, value);
                            setStationData(
                              stationData.map((item, index) => {
                                if (index === idx) {
                                  return {
                                    ...item,
                                    [id]: value,
                                  };
                                }
                                return item;
                              })
                            );
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col className="col-2 col-md-1 p-0 ps-lg-2">
                <span
                  className="add-booking"
                  onClick={() => {
                    setStationData([...stationData, defaultStationData]);
                  }}
                >
                  <AiOutlinePlusCircle />
                </span>
              </Col>
            </Row>

            <Row>
              <Col className="col-12 p-0">
                <Form.Group className="form-field-control mb-2">
                  <Form.Control
                    as="textarea"
                    placeholder="Message"
                    style={{ height: "160px" }}
                    value={formData.message || ""}
                    onChange={(e) => setValue("message", e.target.value)}
                    name="Message"
                  />
                </Form.Group>
              </Col>
              <Col className="col-12 p-0">
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={formSent ? true : false}
                  className="btn"
                >
                  {formSent ? "Submitted" : "Submit"}
                </Button>
              </Col>
              <Col className="col-12 p-0">
                <p
                  className={`ty-text ${formSent ? "d-block" : "d-none"} pt-4`}
                >
                  Thank you for your inquiry. We'll get back to you soon
                </p>
              </Col>
            </Row>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Booking = ({ index, dataPassed, setData = () => null }) => {
  const { name = "", date = "", start = "", end = "" } = dataPassed;

  const data = useStaticQuery(graphql`
    {
      allPrismicCoverageMap {
        nodes {
          data {
            markers {
              name
            }
          }
        }
      }
    }
  `);

  const [startTime, setStartTime] = useState("");

  // useEffect(() => {
  //   const newDate = new Date(startTime);
  //   // console.log(newDate.getMinutes());
  //   // console.log(setMinutes(startTime, 0));
  // }, [startTime]);

  const markers = data.allPrismicCoverageMap.nodes[0].data.markers;
  const currentDate = new Date();
  var priorDate = new Date(new Date().setDate(currentDate.getDate() - 30));

  return (
    <Row className="my-2 mx-2">
      <Col className="col-12 col-md-6 col-xl-3 ps-0 pe-1">
        <Form.Group className="form-field-control mb-2 mb-md-0">
          <Form.Select
            value={name}
            onChange={(e) => setData("name", e.target.value)}
            name={`Station ${index + 1}`}
            className="pe-4"
          >
            <option value={""}>Select Station</option>
            {markers.map(({ name }, idx) => {
              return (
                <option key={idx} value={name}>
                  {name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col className="col-12 col-md-6 col-xl-3 ps-0 pe-1">
        <Form.Group
          className="form-field-control mb-2 mb-md-0"
          controlId="formPhoneNumber"
        >
          <Datepicker
            placeholderText="Select Date"
            minDate={priorDate}
            name={`Date ${index}`}
            className="form-control"
            selected={date}
            onChange={(value) => setData("date", value)}
          />
        </Form.Group>
      </Col>
      <Col className="col-12 col-md-6 col-xl-3 ps-0 pe-1">
        <Form.Group className="form-field-control mb-2 mb-md-0">
          <Datepicker
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="h:mm aa"
            name={`Start Time ${index}`}
            placeholderText="Start Time"
            className="form-control"
            selected={start}
            onChange={(value) => {
              setData("start", value);
              setStartTime(value);
            }}
          />
        </Form.Group>
      </Col>
      <Col className="col-12 col-md-6 col-xl-3 ps-0 pe-1">
        <Form.Group className="form-field-control mb-2 mb-md-0">
          <Datepicker
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            minTime={setMinutes(startTime, 0)}
            maxTime={setHours(setMinutes(startTime, 45), 23)}
            timeCaption="Time"
            dateFormat="h:mm aa"
            name={`End Time ${index}`}
            placeholderText="End Time"
            className="form-control"
            selected={end}
            onChange={(value) => setData("end", value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

function App() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
