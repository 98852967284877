import * as React from "react";
import Layout from "../../layout";
import Pricing from "../../components/HomePage/Pricing/Pricing";
import PostProcessing from "../../components/Services/PostProcessing";

const ServicesPage = () => {
  return (
    <Layout
      title={"Services"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      <Pricing />
      <PostProcessing />
    </Layout>
  );
};

export default ServicesPage;
