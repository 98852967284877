import axios from "axios";

const sendMail = async (to, userEmail = null, fields = []) => {
  console.log("sending email");
  await axios
    .post("/api/sendmail", {
      to,
      userEmail,
      fields,
    })
    .then(() => null)
    .catch((e) => {
      console.log(e);
    });

  return true;
};

export default sendMail;