import React from "react";
import * as s from "./index.module.scss";
import RinexModal from "../Modal/RinexModal";
import ProcessingModal from "../Modal/ProcessingModal";

export default () => {
  const [modalShowRinex, setModalShowRinex] = React.useState(false);
  const [modalShowProcessing, setModalShowProcessing] = React.useState(false);

  return (
    <section className={`processing py-8 py-md-10 ${s.processing}`}>
        <article className="container">
            <h2 className='display-4 text-center mb-8'>POST PROCESSING SERVICES</h2>
            <div className="card-container row justify-content-center align-items-start">
                <div className={`card col-12 mx-4 col-md-6 col-lg-4 ${s.card_shadow} mb-4 mb-md-0`}>
                    <div className="card-body">
                        <h5 className="card-title display-6 text-center mb-2">Post Processing</h5>
                        <p className="card-text text-center">
                            We provide post processing services for your Static Observation campaigns. You just have to upload your raw data files in Rinex 3.02 or higher format with the relevant antenna and receiver details. As per your requirements we provide the adjusted coordinates and other reports in both local and WGS84 coordinates systems.
                        </p>
                        <button className={`btn ${s.module_btn} d-block mx-auto`} onClick={()=>{setModalShowProcessing(true)}}>Contact Us</button>
                        {
                            modalShowProcessing && <ProcessingModal show={modalShowProcessing} onHide={() => setModalShowProcessing(false)}/>
                        }
                    </div>
                </div>
                <div className={`card col-12 mx-4 col-md-6 col-lg-4 ${s.card_shadow}`}>
                    <div className="card-body">
                        <h5 className="card-title display-6 text-center mb-2">Rinex Data</h5>
                        <p className="card-text text-center">
                            <strong>CORSnet</strong> archives data for all its CORS stations around the country in the <strong>Receiver Independent Exchange (RINEX)</strong> format. We currently collect GPS, GLONASS, BEIDOU and GALILEO observational and navigational data from all its CORS stations. These stations can take 24 hrs to upload the latest RINEX data to the server. So you can check the data availability and send request to download the data from the server. The data are recorded at 10 second intervals and supplied as RINEX Format 3.02 files under 24-hour duration.
                        </p>
                        <button className={`btn ${s.module_btn} d-block mx-auto`} onClick={()=>{setModalShowRinex(true)}}>Contact Us</button>
                        {
                            modalShowRinex && <RinexModal show={modalShowRinex} onHide={() => setModalShowRinex(false)}/>
                        }
                    </div>
                </div>
            </div>
      </article>
    </section>
  );
};
