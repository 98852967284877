const formatStationData = (stationData) => {
  const formattedSd = [];

  const formattedStationData = stationData.map((d, idx) => {
    const tempD = [
      {
        key: `Station ${idx + 1} Name`,
        value: d.name,
      },
      {
        key: `Station ${idx + 1} Date`,
        value: d.date,
      },
      {
        key: `Station ${idx + 1} Start Time`,
        value: d.start,
      },
      {
        key: `Station ${idx + 1} End Time`,
        value: d.end,
      },
    ];
    formattedSd.push(...tempD);
    return tempD;
  });

  return formattedSd;
};

export default formatStationData;
