import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "./pricing.scss";
import { connectionTypes } from "../../../pages/user/new-connection";
import currencyFormat from "../../../utils/currencyFormat";
import { AuthContext } from "../../../auth";

export default function SimpleSlider() {
  const [slidesToShow, setSlidesToShow] = useState(0);

  const {
    user: { loggedIn },
  } = useContext(AuthContext);

  const handleSlides = () => {
    if (typeof window === "undefined") {
      setSlidesToShow(1);
      return;
    }

    if (window.innerWidth < 576) {
      setSlidesToShow(1);
    } else if (window.innerWidth < 768) {
      setSlidesToShow(2);
    } else if (window.innerWidth < 992) {
      setSlidesToShow(2);
    } else if (window.innerWidth < 1200) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(4);
    }
  };

  useEffect(() => {
    handleSlides();
    window.addEventListener("resize", handleSlides);
    return () => window.removeEventListener("resize", handleSlides);
  }, []);

  if (!slidesToShow) {
    return null;
  }
  return (
    <section className="pricing wrapper bg-light">
      <div className="container py-10 py-md-12">
        <h2 className="display-5 mb-2 text-center">
          REAL-TIME SERVICE PACKAGES
        </h2>
        <p className="display-6 mb-5 text-center">
          Choose a plan to access our service
        </p>
        <div className="pricing-wrapper">
          <div className="row gx-0 gy-6 mt-2">
            <article className="slider-wrapper py-2">
              <Slider
                infinite={true}
                speed={250}
                dots={false}
                autoplaySpeed={4000}
                autoplay={false}
                cssEase="linear"
                slidesToShow={slidesToShow}
                slidesToScroll={1}
              >
                {connectionTypes.map(
                  (
                    { period, price, pdc, available, ud, notAvailable },
                    idx
                  ) => (
                    <div className="col-md-6 col-lg-3" key={idx}>
                      <div className="pricing card box-shadow-prop">
                        <div className="card-body text-center">
                          <div className="icon btn btn-circle btn-lg btn-soft-primary disabled">
                            {idx + 1}
                          </div>
                          <h4 className="card-title mb-4">{period}</h4>
                          <div className="prices text-dark">
                            <div className="price price-show justify-content-center position-relative">
                              <span className="price-currency">Rs.</span>
                              <span className="price-value">
                                {currencyFormat(price, {
                                  noLabel: true,
                                  noCents: true,
                                })}
                              </span>
                            </div>
                          </div>
                          <div className="per-day-prices">
                            {`${pdc}` !== " " ? (
                              <div className="price-currency pb-2 fs-18">
                                Per Day Rs. {pdc}
                              </div>
                            ) : (
                              <div className="price-currency py-4 fs-18">
                                {pdc}
                              </div>
                            )}
                          </div>
                          <ul className="icon-list bullet-bg bullet-soft-primary mt-2 mb-9 text-start">
                            {available.map((a, idx) => {
                              return (
                                <li key={idx}>
                                  <i className="uil uil-check"></i>
                                  <span>{a}</span>
                                </li>
                              );
                            })}
                            <li>
                              <i className="uil uil-check"></i>
                              <span>
                                User Demonstration <strong>{ud}</strong>
                              </span>
                            </li>
                            {notAvailable.map((na, idx) => {
                              return (
                                <li key={idx}>
                                  <i className="uil uil-times bullet-soft-red"></i>
                                  <span>{na}</span>
                                </li>
                              );
                            })}
                          </ul>
                          <Link
                            to={
                              loggedIn
                                ? `/user/new-connection`
                                : `/user/register`
                            }
                            className="btn btn-soft-primary rounded-pill"
                          >
                            Choose Plan
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
